<template>
  <q-layout id="top" view="hHh lpR fff">
    <q-header>
      <q-toolbar class="h-7 min-h-min bg-black flex justify-end -gap-x-4">
        <q-btn-dropdown
          :label="$t('MainLayout.header.websites')"
          flat
          dense
          round
          content-class="left-0! right-0! max-w-none! w-full flex justify-center"
        >        
          <ul class="container flex justify-between items-center h-36">
            <li>
              <router-link
                class="text-gray-700 text-xl py-3 flex items-center"
                to="/"
              >
                <q-icon
                  class="text-green-600"
                  name="mdi-chevron-right"
                  size="30px"
                />
                <span>{{ $t('MainLayout.header.company') }}</span>
              </router-link>
              <div class="text-gray-400 text-lg py-3">
                {{ $t('MainLayout.header.information') }}
              </div>
            </li>
            <li>
              <a
                class="text-gray-700 text-xl py-3 flex items-center"
                href="https://salaxy.s-iis.com/"
                target="_blank"
              >
                <span>{{ $t('MainLayout.header.salaxy') }}</span>
                <q-icon
                  class="text-gray-500"
                  name="mdi-chevron-right"
                  size="30px"
                />
              </a>
              <div class="text-gray-400 text-lg py-3">{{ $t('MainLayout.header.salaxyProduct') }}</div>
            </li>
            <li>
              <a
                class="text-gray-700 text-xl py-3 flex items-center"
                href="https://www.s-oceanmind.com/"
                target="_blank"
              >
                <span>{{ $t('MainLayout.header.oceanmind') }}</span>
                <q-icon
                  class="text-gray-500"
                  name="mdi-chevron-right"
                  size="30px"
                />
              </a>
              <div class="text-gray-400 text-lg py-3">{{ $t('MainLayout.header.oceanmindProduct') }}</div>
            </li>
            <li>
              <a
                class="text-gray-700 text-xl py-3 flex items-center"
                href="https://recruit.sinovatio.com"
                target="_blank"
              >
                <span>{{ $t('MainLayout.header.joinsec') }}</span>
                <q-icon
                  class="text-gray-500"
                  name="mdi-chevron-right"
                  size="30px"
                />
              </a>
              <div class="text-gray-400 text-lg py-3">{{ $t('MainLayout.header.recruit') }}</div>
            </li>
          </ul>
        </q-btn-dropdown>
        <ChangeLanguage />        
        <div>
          <q-icon name="mdi-phone" />
          <a
            class="px-1 text-sm"
            :href="`tel:${settings[$t('MainLayout.header.hotline')] ?? '400-100-8102'}`"
            >{{ settings[$t('MainLayout.header.hotline')] ?? '400-100-8102' }}</a
          >
        </div>
      </q-toolbar>
      <q-toolbar class="h-16 min-h-min bg-white flex justify-between">
        <div class="w-full px-10 flex justify-between items-center">
          <img class="w-36 h-14" src="/img/syi_icon_logo@2x_v2.png" />

          <div class="flex">
            <template v-for="page in menus" :key="page.id">
              <!-- <sv-btn-dropdown
                v-if="page.children?.length"
                class="no-dropdown-icon h-16 lg:px-4 xl:px-8 text-base text-gray-800 hover:text-green-600"
                content-class="left-0! right-0! max-w-none! w-full flex justify-center"
                :to="tmpl(page.url!, page)"
                :label="page.title"
                flat
              >
                <sv-product-menu
                  v-if="page.title === '产品与解决方案'"
                  :pages="page.children"
                />
                <div
                  v-else
                  class="container py-8 flex justify-center -gap-x-24 text-gray-500"
                >
                  <template v-for="child in page.children" :key="child.id">
                    <router-link
                      v-if="child.url"
                      class="hover:text-green-600"
                      :to="tmpl(child.url, child)"
                      >{{ child.title }}</router-link
                    >
                  </template>
                </div>
              </sv-btn-dropdown> -->
              <q-btn
                v-if="page.children?.length"
                class="h-16 lg:px-4 xl:px-6 text-base text-gray-800 hover:text-green-600"
                :label="page.title"
                flat
                @mouseenter="
                  () => {
                    state.page = page;
                    state.menuOver = true;
                  }
                "
                @mouseover="
                  () => {
                    state.page = page;
                    state.menuOver = true;
                  }
                "
                @mouseout="
                  () => {
                    state.page = page;
                    state.menuOver = false;
                  }
                "
                @click="
                  (e) => {
                    e.preventDefault();
                    e.stopPropagation();
                  }
                "
              >
              </q-btn>
              <q-btn
                v-else
                class="h-16 lg:px-4 xl:px-6 text-base text-gray-800 hover:text-green-600"
                :to="tmpl(page.url!, page)"
                :label="page.title"
                flat
              ></q-btn>
            </template>
          </div>

          <q-btn
            class="text-gray-800 hover:text-green-600"
            to="/news/search"
            icon="mdi-magnify"
            flat
            round
          />
        </div>

        <q-popup-proxy
          class="left-0! right-0! max-w-none! w-full flex justify-center animate__animated animate__faster"
          v-model="state.visible"
          no-parent-event
        >
          <div
            class="w-full flex justify-center"
            @mouseover="() => (state.listOver = true)"
            @mouseout="() => (state.listOver = false)"
          >
            <sv-product-menu
              v-if="state.page.title === $t('MainLayout.header.product')"
              :pages="state.page.children"
            />
            <div
              v-else
              class="container py-8 flex justify-center -gap-x-24 text-gray-500"
            >
              <div class="hidden"></div>
              <template v-for="child in state.page.children" :key="child.id">
                <a
                  v-if="
                    /^https:\/\//.test(child.url) ||
                    /^http:\/\//.test(child.url)
                  "
                  class="hover:text-green-600"
                  :href="tmpl(child.url!, child)"
                  target="_blank"
                  >{{ child.title }}</a
                >
                <router-link
                  v-else
                  class="hover:text-green-600"
                  :to="tmpl(child.url!, child)"
                  >{{ child.title }}</router-link
                >
              </template>
              <div class="hidden"></div>
            </div>
          </div>
        </q-popup-proxy>
      </q-toolbar>
    </q-header>

    <q-page-container>
      <router-view />
      <q-dialog v-model="state.dialogVisible" class="overflow-hidden" >
        <!-- <q-layout view="Lhh lpR fFf" container class="bg-white contact-dialog"> -->
        <!-- </q-layout> -->
          <q-card class="contact-dialog">
            <!-- <q-card-section align="right">
              <q-toolbar-title></q-toolbar-title>
              <q-btn flat v-close-popup="state.dialogVisible" round dense circle icon="mdi-close" color="bg-white" class="text-gray-900" />
            </q-card-section> -->
            <q-separator />
            <q-card-section class="scroll">
              <ContactPopupPage />              
            </q-card-section>
          </q-card>       
      </q-dialog>
    </q-page-container>    

    <q-footer class="bg-white flex justify-center text-gray-500">
      <div class="container lg:px-8 xl:px-8 2xl:px-0">
        <div class="flex justify-between py-8">
          <div class="flex flex-col content-between -gap-y-8">
            <div>
              <div class="py-2 text-lg font-bold">{{$t('MainLayout.footer.follow')}}</div>
              <ul class="flex -gap-x-4">
                <li class="hidden"></li>
                <li>
                  <q-btn padding="0" flat round>
                    <img
                      class="w-8 h-8 cursor-pointer"
                      src="/img/syi_icon_weixin@2x.png"
                    />
                    <q-popup-proxy :offset="[70, -230]">
                      <q-banner class="w-44 h-44 p-0">
                        <template v-slot:avatar>
                          <img
                            class="w-44 h-44"
                            src="/img/sy_icon_qrcode@2x.png"
                          />
                        </template>
                      </q-banner>
                    </q-popup-proxy>
                  </q-btn>
                </li>
                <li>
                  <a
                    href="https://www.toutiao.com/c/user/token/MS4wLjABAAAAudQWo7SNq67fiKsSB6a6AWykvfobMSdXgVEGnDuF3tE/?source=list&log_from=1ff4600971f29_1675931233896"
                    target="_blank"
                  >
                    <img class="w-8 h-8" src="/img/syi_icon_toutiao@2x.png" />
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.zhihu.com/people/sectan-ying-zhe"
                    target="_blank"
                  >
                    <img class="w-8 h-8" src="/img/syi_icon_zhihu@2x.png" />
                  </a>
                </li>
                <li>
                  <a href="https://weibo.com/u/7648556721" target="_blank">
                    <img class="w-8 h-8" src="/img/syi_icon_weibo@2x.png" />
                  </a>
                </li>
                <li class="hidden"></li>
              </ul>
            </div>
            <div>
              <div class="py-2 text-lg font-bold">{{$t('MainLayout.footer.hotline')}}</div>
              <div>
                <a
                  class="text-lg font-bold"
                  :href="`tel:${settings[$t('MainLayout.footer.hotline')] ?? $t('MainLayout.header.phone')}`"
                  >{{ settings[$t('MainLayout.footer.hotline')] ?? $t('MainLayout.header.phone') }}</a
                >
                <span class="text-sm pl-2"
                  >({{ settings[$t('MainLayout.footer.time')] ?? $t('MainLayout.footer.weekdays') }})</span
                >
              </div>
            </div>
          </div>
          <ul class="flex justify-center -gap-x-36 py-2">
            <li v-if="about && about.children?.length">
              <div class="mb-4 text-lg font-bold">{{$t('MainLayout.footer.about')}}</div>
              <ul>
                <li
                  class="mt-2"
                  v-for="child in about.children"
                  :key="child.id"
                >
                  <router-link
                    class="hover:text-green-600"
                    :to="tmpl(child.url!, child)"
                    >{{ child.title }}</router-link
                  >
                </li>
              </ul>
            </li>
            <li v-if="invest && invest.children?.length">
              <div class="mb-4 text-lg font-bold">{{$t('MainLayout.footer.invest')}}</div>
              <ul>
                <li
                  class="mt-2"
                  v-for="child in invest.children"
                  :key="child.id"
                >
                  <a
                    v-if="
                      /^https:\/\//.test(child.url) ||
                      /^http:\/\//.test(child.url)
                    "
                    class="hover:text-green-600"
                    :href="tmpl(child.url!, child)"
                    target="_blank"
                    >{{ child.title }}</a
                  >
                  <router-link
                    v-else
                    class="hover:text-green-600"
                    :to="tmpl(child.url!, child)"
                    >{{ child.title }}</router-link
                  >
                </li>
              </ul>
            </li>
            <li>
              <div class="mb-4">
                <a
                  class="text-lg font-bold"
                  href="https://recruit.sinovatio.com"
                  target="_blank"
                  >{{$t('MainLayout.footer.join')}}</a
                >
              </div>
              <div class="mb-4">
                <router-link class="text-lg font-bold" to="/news"
                  >{{$t('MainLayout.footer.news')}}</router-link
                >
              </div>
              <div class="mb-4">
                <router-link class="text-lg font-bold" to="/support"
                  >{{$t('MainLayout.footer.service')}}</router-link
                >
              </div>
            </li>
          </ul>
        </div>
        <q-separator />
        <div class="py-4 flex justify-between">
          <img class="w-36 h-14" src="/img/syi_icon_logo@2x_v2.png" />
          <div class="text-right">
            <div>
              {{
                settings['Copyright'] ??
                $t('MainLayout.footer.copyright')
              }}
            </div>
            <div
              v-html="settings[$t('MainLayout.footer.filing')] ?? $t('MainLayout.footer.record')"
            ></div>
          </div>
        </div>
        <!-- <div class="relative">
          <Leaf class="absolute right-0" style="bottom: -10px" />
        </div> -->

        <div class="fixed right-10 bottom-36 flex flex-col -gap-y-2">
          <q-btn-group class="flex flex-col items-center bg-white" rounded>
            <q-btn icon="mdi-phone" fab-mini>
              <q-tooltip anchor="center left" self="center right">
                <div>{{$t('MainLayout.suspend.hotline')}}</div>
                <div>{{ settings[$t('MainLayout.suspend.hotline')] ?? $t('MainLayout.header.phone') }}</div>
              </q-tooltip>
            </q-btn>
            <q-separator class="w-7" />
            <q-btn
              icon="mdi-comment-processing-outline"
              fab-mini
              @click="state.dialogVisible = true"
            >
              <q-tooltip anchor="center left" self="center right">
                {{$t('MainLayout.suspend.online')}}
              </q-tooltip>
            </q-btn>
          </q-btn-group>
          <q-btn-group class="flex flex-col bg-white" rounded>
            <q-btn icon="mdi-arrow-up-bold" fab-mini v-scroll-to="'#top'">
              <q-tooltip anchor="center left" self="center right">
                {{$t('MainLayout.suspend.top')}}
              </q-tooltip>
            </q-btn>
          </q-btn-group>
        </div>
      </div>
    </q-footer>
  </q-layout>
</template>

<script lang="ts">
import { PageWithChildrenDto, usePageStore } from 'stores/modules/page';
import { useMetaStore } from 'stores/modules/meta';

export default {
  async preFetch({ store }) {
    const metaStore = useMetaStore(store);
    await metaStore.fetchAll();

    const pageStore = usePageStore(store);
    await pageStore.fetchAll();
  },
};
</script>

<script setup lang="ts">
import { reactive, watch } from 'vue';
import { useMeta, debounce } from 'quasar';
import ChangeLanguage from 'components/ChangeLanguage.vue'
// import SvBtnDropdown from 'components/SvBtnDropdown.vue';
import SvProductMenu from 'components/SvProductMenu.vue';
import tmpl from 'src/utils/tmpl';
import { useI18n } from 'vue-i18n';
import ContactPopupPage from 'components/ContactPopupPage.vue'

const { t } = useI18n();
const state = reactive<{
  page?: any;
  menuOver: boolean;
  listOver: boolean;
  visible: boolean;
  dialogVisible: boolean;
}>({
  menuOver: false,
  listOver: false,
  visible: false,
  dialogVisible: false,
});

const debounceFunc = debounce(() => {
  state.visible = state.menuOver || state.listOver;
});

watch(
  () => [state.menuOver, state.listOver],
  () => {
    debounceFunc();
  }
);

const metaStore = useMetaStore();
useMeta(metaStore.meta);
const settings = metaStore.settings;

const pageStore = usePageStore();
const menus = pageStore.menus;
const about = menus.filter((x) => x.title === t('MainLayout.footer.about'))[0];
const invest = menus.filter((x) => x.title === t('MainLayout.footer.invest'))[0];
</script>

<style>
.left-0\! {
  left: 0 !important;
}
.right-0\! {
  right: 0 !important;
}
.max-w-none\! {
  max-width: none !important;
}
.no-dropdown-icon .q-icon.q-btn-dropdown__arrow {
  display: none;
}
.q-banner__avatar {
  justify-content: center;
}
.contact-container {
  padding-top: 0rem;
  padding-bottom: 0rem;
}
.contact-dialog {
  position: absolute !important;
  right: 6rem;
}
</style>
