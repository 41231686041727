<template>
  <div class="container px-0 flex">
    <div class="flex-none pt-2 border-r-gray-200 border-r">
      <q-tabs v-model="tab" vertical active-class="text-green-600">
        <template v-for="(page, i) in pages" :key="page.id">
          <q-tab
            class="flex justify-start"
            v-if="page.children?.length"
            :name="i"
            :ripple="false"
          >
            <div class="flex items-center -gap-x-4 px-8">
              <img
                v-if="page.icon"
                class="w-6 h-6"
                :src="page.icon"
                alt="icon"
              />
              <span>{{ page.title }}</span>
            </div>
          </q-tab>
        </template>
      </q-tabs>
    </div>
    <div class="flex-1">
      <q-tab-panels
        v-model="tab"
        animated
        swipeable
        vertical
        transition-prev="jump-up"
        transition-next="jump-up"
      >
        <template v-for="(page, i) in pages" :key="page.id">
          <q-tab-panel
            class="pt-5 overflow-y-hidden"
            v-if="page.children?.length"
            :name="i"
          >
            <div class="px-4 flex justify-between -gap-x-4">
              <template v-for="child in page.children" :key="child.id">
                <div class="">
                  <div class="mb-4 text-gray-900">
                    <router-link
                      v-if="child.url"
                      :to="tmpl(child.url, child)"
                      >{{ child.title }}</router-link
                    >
                    <span v-else>{{ child.title }}</span>
                  </div>
                  <ul class="text-gray-400">
                    <template
                      v-for="grandson in child.children"
                      :key="grandson.id"
                    >
                      <li
                        class="pb-3 flex items-center hover:text-green-600 visited:text-green-600"
                      >
                        <router-link
                          v-if="grandson.url"
                          :to="tmpl(grandson.url, grandson)"
                          >{{ grandson.title }}</router-link
                        >
                        <span v-else>{{ grandson.title }}</span>
                        <span
                          v-if="grandson.keywords.label"
                          :class="`ml-2 px-1.5 py-0.5 text-xs tag-${grandson.keywords.type}`"
                          >{{ grandson.keywords.label }}</span
                        >
                      </li>
                    </template>
                  </ul>
                </div>
              </template>
            </div>
          </q-tab-panel>
        </template>
      </q-tab-panels>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref } from 'vue';
import { PageWithChildrenDto } from 'stores/modules/page';
import tmpl from 'src/utils/tmpl';

defineProps<{
  pages: PageWithChildrenDto[];
}>();

const tab = ref<number>(0);
</script>

<style scoped>
.tag-danger, .tag-4 {
  color: #f66540;
  background-color: #ffe1da;
}

.tag-success, .tag-1 {
  color: #30ab39;
  background-color: #e1f4e3;
}

.tag-default, .tag-0 {
  color: #009de9;
  background-color: #d8f0fb;
  border: 1px solid #009de9;
  border-radius: 2px;
}
</style>
